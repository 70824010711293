import { $ } from '../helper/utils';

export default {
  /**
  * Initialise slider
  * App is an instance of Framework7
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {object} sel the sipwer container element
  */
  initSlider(App, sel, pag) {
    App.swiper(sel, {
      speed: 400,
      slidesPerView: 1,
      pagination: pag,
    });
  },

  /**
  * Initialise touch handlers
  * This also includes handlers to prevent selection/deselection when
  * changing slides by swiping either right or left
  * @param {object} sel
  * @param {object} container
  */
  initSelection(sel, container) {
    let dragging = false;

    sel.on('touchmove', () => {
      dragging = true;
    });

    sel.on('click', function () {
      if (dragging) {
        return;
      }

      this.classList.toggle('selected');
    });

    sel.on('touchstart', () => {
      dragging = false;
    });
  },
};
