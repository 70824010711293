import { DOMAIN } from './constants';
import { loadFromUrl, queryString } from './utils';

/**
* Gets the specified data either from a server or from the sessionStorage if aviable
* App is an instance of Framework7
* params specify the data to get
*
* @param {Function} App
* @param {Function} $$
* @param {Object} params
* @return {Function} callback
*/
export default (App, force, params, callback) => {
  const local = JSON.parse(sessionStorage.getItem('data'));

  // check if there is a local copy of the data
  if (params.mode in local && !force) {
    return callback(local[params.mode]);
  }

  const data = {
    ajax: 1,
    modulname: 'ajax',
    hotel_id: localStorage.getItem('hotelid'),
    tablenumber: localStorage.getItem('tablenumber'),
  };
  const url = `${DOMAIN}/admin/index1.php${queryString({ ...data, ...params })}`;
  const request = loadFromUrl(url, 'json');

  return request.then((response) => {
    // save to session storage
    local[params.mode] = response;
    sessionStorage.setItem('data', JSON.stringify(local));

    return callback(local[params.mode]);
  });
};