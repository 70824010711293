import nunjucks from 'nunjucks';
import { DOMAIN, HOTEL_SELECTION } from '../helper/constants';
import { loadFromUrl, queryString, $ } from '../helper/utils';

export const loadDashboard = (App, mainView, logindata, hotelid, loadView = true) => {
  const loginrequestData = {
    ajax: 1,
    modulname: 'ajax',
    mode: 'dashboard',
    employee_id: logindata.data.employee_id,
    number: logindata.data.number,
    hotel_id: hotelid,
    tablenumber: localStorage.getItem('tablenumber'),
  };
  const loginrequestUrl = `${DOMAIN}/admin/index1.php${queryString(loginrequestData)}`;
  const userRequest = loadFromUrl(loginrequestUrl, 'json');

  return userRequest.then((dashboard) => {
    if (dashboard.status === false) {
      App.addNotification({
        title: 'Stampfli',
        message: dashboard.msg,
        hold: 3000,
      });
    } else {
      const savedata = {
        employee: dashboard.data.employee_data,
        employeetime: dashboard.data.employee_time,
        projecttime: dashboard.data.project_time,
        projectname: dashboard.data.project_name,
      };

      const elapsedtimes = {
        work: dashboard.data.employee_time.elapsedtime,
        project: dashboard.data.totaltime,
      };

      const hasProjectEndTime = dashboard.data.project_time !== null ? (
        dashboard.data.project_time.endtime !== '0000-00-00 00:00:00') : false;

      if (hasProjectEndTime === true) {
        sessionStorage.setItem('projectStopped', true);
      }

      dashboard.data.hasProjectEndTime = hasProjectEndTime; // eslint-disable-line no-param-reassign
      dashboard.data.DOMAIN = DOMAIN; // eslint-disable-line no-param-reassign

      // save for later reference
      sessionStorage.setItem('data', JSON.stringify(savedata));
      sessionStorage.setItem('elapsedtimes', JSON.stringify(elapsedtimes));

      if (loadView === true) {
        mainView.router.load({
          // url: '/dashboard.html'
          content: nunjucks.render('dashboard.html', dashboard.data),
        });
        return Promise.resolve({ loadView });
      }

      return Promise.resolve({ loadView });
    }
  });
};

export default (App, mainView, personalnumber) => {
  const hotelid = localStorage.getItem('hotelid');
  const showHotelselection = parseInt(personalnumber, 10) === HOTEL_SELECTION;
  const mode = showHotelselection === true ? 'gethotels' : 'login';
  const data = {
    mode,
    personalnumber,
    ajax: 1,
    modulname: 'ajax',
    hotel_id: hotelid,
  };
  const url = `${DOMAIN}/admin/index1.php${queryString(data)}`;
  const loginRequest = loadFromUrl(url, 'json');

  loginRequest.then((logindata) => {
    // console.log(showHotelselection);

    // show hotel selection screen
    if (showHotelselection === true) {
      for (let i = logindata.data.length - 1; i >= 0; i -= 1) {
        if (logindata.data[i].hotel_id === hotelid) {
          logindata.data[i].selected = true; // eslint-disable-line no-param-reassign
        } else {
          logindata.data[i].selected = false; // eslint-disable-line no-param-reassign
        }
      }

      return mainView.router.load({
        content: nunjucks.render('hotels.html', {
          hotels: logindata.data.sort(function(a, b) {
            // put at end if name is empty
            if (a.name === '') {
              return 1;
            }

            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          }),
        }),
      });

      // show next login screen
    }

    // personalnumber not found
    if (logindata.status === false) {
      mainView.router.load({
        content: nunjucks.render('loginfail.html', { error: logindata.msg }),
      });

      sessionStorage.setItem('loggedin', false);

      return setTimeout(() => {
        mainView.router.load({
          content: nunjucks.render('benutzerid.html', {}),
        });
      }, 'hotel' in logindata ? 7000 : 2000);
    }

    mainView.router.load({
      content: nunjucks.render('loginok.html', { username: logindata.data.firstname }),
    });

    sessionStorage.setItem('loggedin', true);
    sessionStorage.setItem('fromlogin', true);

    setTimeout(() => {
      loadDashboard(App, mainView, logindata, hotelid, true);
    }, 1000);
  }).catch((error) => {
    console.error(error);
    mainView.router.load({
      content: nunjucks.render('loginfail.html', { error: 'Loginabfrage fehlgeschlagen' }),
    });
  });
};
