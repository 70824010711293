import QrScanner from '../../libs/qr/qr-scanner.min';
import showNotification from '../helper/showNotification';

export default class Scanner {
  constructor(app) {
    if (!app) {
      console.error('No app instance provided');
      return;
    }

    this.app = app;
    this.video = document.createElement('video');
    this.video.muted = true;
    this.video.playsinline = true;
    this.closeBtn = document.createElement('button');
    this.closeBtn.classList.add('close-scanner');
    this.closeBtn.textContent = 'Abbrechen';
    this.wrapper = document.createElement('aside');
    this.wrapper.classList.add('scanner');
    this.wrapper.appendChild(this.video);
    this.wrapper.appendChild(this.closeBtn);
    document.body.appendChild(this.wrapper);

    const bindClose = this.closeScanner.bind(this);
    this.closeBtn.addEventListener('click', bindClose, { passive: true });
  }

  openScanner() {
    requestAnimationFrame(() => {
      if (!this.scanner) {
        this.scanner = new QrScanner(this.video, (result) => {
          console.log(result);
          this.closeScanner();
          window.dispatchEvent(new CustomEvent('qrcode:scan', { detail: { result } }));
        });
      }

      this.scanner.start()
        .then(() => {
          this.wrapper.classList.add('show');
        })
        .catch(() => {
          showNotification(this.app, 'Kamera stream nicht verfügbar.');
          this.scanner._qrWorker.terminate();
        });
    });
  }

  closeScanner() {
    requestAnimationFrame(() => {
      this.wrapper.classList.remove('show');
    });

    if (!this.scanner) {
      console.warn('No scanner to stop defined');
      return;
    }

    this.scanner.stop();
  }
}