import logout from '../components/logout';

/**
* Detects inactivity and exeutes a funtion after the specifed time passed
* @param {Function} App a reference to the App instance of Framework 7
* @param {Object} mv a reference to mainView
* @param {Array} stopwatches
* @param {Number} timer
*/
export default (App, mv, stopwatches, page, waittime) => {
  const timer = waittime * 1000 || 60 * 1000;
  let interval = setInterval(() => {
    clearInterval(interval);
    logout(App, mv, stopwatches);
  }, timer);

  const clearTimer = () => {
    clearInterval(interval);
    if (!page.match(/dashboard|chooseproject|project|projectworks|rooms|roomcheck|report-overview|report-hotels|report-view/)) {
      interval = setInterval(() => {
        clearInterval(interval);
        logout(App, mv, stopwatches);
      }, timer);
    }
  };

  window.addEventListener('click', clearTimer, { passive: true });
  window.addEventListener('touchmove', clearTimer, { passive: true });
  window.addEventListener('keyup', clearTimer);

  return interval;
};
