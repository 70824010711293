import nunjucks from 'nunjucks';
import getData from '../helper/getData';
import showNotification from '../helper/showNotification';
import logout from './logout';

function formatSeconds(seconds) {
  const sec = parseInt(seconds, 10); // don't forget the second param
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let secondas = sec - (hours * 3600) - (minutes * 60);
  const times = {
    hours: hours > 0 ? `${hours}h` : 0,
    minutes: minutes > 0 ? `${minutes} Min` : 0,
  };

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondas < 10) {
    secondas = `0${secondas}`;
  }

  return {
    times,
    stringTime: `${hours}:${minutes}:${secondas}`,
  };
}

export default {
  /**
  * Start the project time and restart app
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {Object} mv a reference to mainView
  * @param {Int} projectid
  * @param {Array} stopwatches
  */
  start(App, mv, projectid, stopwatches, redirect = true) {
    if (!App) {
      console.error('No reference to App provided');
      return false;
    }

    if (!mv) {
      console.error('No reference to the mainView provided');
      return false;
    }

    if (!projectid) {
      console.error('No projectid provided');
      return false;
    }

    const sd = JSON.parse(sessionStorage.getItem('data'));

    return getData(App, true, {
      mode: 'startprojecttime',
      project_id: projectid,
      employee_id: sd.employee.employee_id,
    }, (data) => {
      if (data.status) {
        if (redirect === true) {
          mv.router.load({
            url: 'timestarted.html',
          });

          // reset App
          setTimeout(() => {
            logout(App, mv, stopwatches);
          }, 3000);
        }
      } else {
        showNotification(App, data.msg);
      }
    });
  },
  /**
  * Start the project time and restart app
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {Object} mv a reference to mainView
  * @param {Function} callback
  */
  stop(App, mv, callback) {
    if (!App) {
      console.error('No reference to App provided');
      return false;
    }

    if (!mv) {
      console.error('No reference to the mainView provided');
      return false;
    }

    if (!callback) {
      console.error('No callback provided');
      return false;
    }

    const sd = JSON.parse(sessionStorage.getItem('data'));

    return getData(App, true, {
      mode: 'stopprojecttime',
      project_id: sd.projecttime.project_id,
      employee_id: sd.employee.employee_id,
    }, (data) => {
      if (data.status) {
        callback(data);
      } else {
        showNotification(App, data.msg);
      }
    });
  },
  /**
  * Get the final project time data and route to confirm view
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {Object} mv a reference to mainView
  */
  finalTime(App, mv) {
    if (!App) {
      console.error('No reference to App provided');
      return false;
    }

    if (!mv) {
      console.error('No reference to the mainView provided');
      return false;
    }

    const sd = JSON.parse(sessionStorage.getItem('data'));

    return getData(App, true, {
      mode: 'finalprojecttime',
      employee_id: sd.employee.employee_id,
      project_id: sd.projecttime.project_id,
    }, (data) => {
      if (data.status) {
        const totalTime = formatSeconds(data.totalprojecttime).stringTime;
        console.log(totalTime);

        // remove seconds
        data.starttime = data.starttime.slice(0, -3); // eslint-disable-line no-param-reassign
        data.endtime = data.endtime.slice(0, -3); // eslint-disable-line no-param-reassign

        // use small treshold of 15 seconds where no pause is shown
        if (data.rest < 15) {
          data.pause = 'Keine'; // eslint-disable-line no-param-reassign
        } else {
          const pauseTimes = (data.rest > 0 && data.rest < 60) ? formatSeconds(60) : formatSeconds(data.rest);
          data.pause = Object.values(pauseTimes.times).filter(t => t !== 0).join(' und '); // eslint-disable-line no-param-reassign
        }

        // if ('roommanagement' in sd) {
        //   let total = sd.roommanagement.data.total.slice(0, -3).split(':');

        //   // format time to just get the necessary value
        //   if (total[0] === '00') {
        //     total = total.splice(1);

        //     // remove trailing zeros
        //     total = `${parseInt(total[0], 10)}m`;
        //   } else if (total[1] === '00') {
        //     total = total.splice(0);

        //     const hours = parseInt(total[0], 10);

        //     if (hours >= 5 && hours < 9) {
        //       data.pause = '30 min'; // eslint-disable-line no-param-reassign
        //     } else if (hours >= 9) {
        //       data.pause = '1 h'; // eslint-disable-line no-param-reassign
        //     }
        //   } else {
        //     // remove trailing zeros
        //     const hours = parseInt(total[0], 10);

        //     if (hours >= 5 && hours < 9) {
        //       data.pause = '30 min'; // eslint-disable-line no-param-reassign
        //     } else if (hours >= 9) {
        //       data.pause = '1 h'; // eslint-disable-line no-param-reassign
        //     }
        //   }
        // } else {
        //   data.pause = '30 min'; // eslint-disable-line no-param-reassign
        // }

        mv.router.load({
          content: nunjucks.render('confirm-time.html', {
            projectname: sd.project_name,
            data,
            totalTime,
          }),
        });
      } else {
        showNotification(App, data.msg);
      }
    });
  },
};
