import Timer from '../../libs/timer';

export default function (el, watchtype) {
  const timerInstance = new Timer();
  const element = el;
  let isRunning = false;

  function updateSeconds() {
    if (element == null) {
      timerInstance.removeEventListener('secondsUpdated', updateSeconds);
      return;
    }

    element.textContent = timerInstance.getTimeValues().toString();
  }

  return {
    isRunning() {
      return isRunning;
    },
    // Start or resume
    start() {
      // get start time
      const start = JSON.parse(sessionStorage.getItem('elapsedtimes'));
      // console.log(start.work);

      if (watchtype === 'project') {
        const seconds = parseInt(start.project, 10);
        console.log(seconds);
        if (typeof seconds === 'number') {
          timerInstance.start({ startValues: { seconds: start.project } });
        } else {
          timerInstance.start();
        }
      } else {
        const seconds = parseInt(start.work, 10);
        console.log(seconds);
        if (typeof seconds === 'number') {
          timerInstance.start({ startValues: { seconds: start.work } });
        } else {
          timerInstance.start();
        }
      }

      timerInstance.addEventListener('secondsUpdated', updateSeconds);

      isRunning = true;
    },

    // Stop or pause
    stop(pause = false) {
      const sd = JSON.parse(sessionStorage.getItem('elapsedtimes'));

      console.log(pause);

      if (pause === true) {
        timerInstance.pause();
        isRunning = false;
        timerInstance.removeEventListener('secondsUpdated', updateSeconds);
      } else {
        timerInstance.start();
        timerInstance.addEventListener('secondsUpdated', updateSeconds);
        isRunning = true;
      }

      const { seconds } = timerInstance.getTotalTimeValues();

      // update elapsedtime
      if (sd) {
        if (watchtype === 'project') {
          sd.project = seconds;
        } else {
          sd.work = seconds;
        }

        sessionStorage.setItem('elapsedtimes', JSON.stringify(sd));
      }
    },

    getSeconds() {
      return timerInstance.getTotalTimeValues().seconds;
    },

    // Reset
    reset() {
      timerInstance.reset();
    },
  };
}
