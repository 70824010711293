import nunjucks from 'nunjucks';
import getData from '../helper/getData';
import showNotification from '../helper/showNotification';

export default {
  /**
  * Show the roommanagement overview
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {Object} mv a reference to mainView
  * @param {Object} project additional data from the clicked project
  */
  show(App, mv, project) {
    if (!App) {
      console.error('No reference to App provided');
      return false;
    }

    if (!mv) {
      console.error('No reference to the mainView provided');
      return false;
    }

    if (!project) {
      console.error('No project data provided');
      return false;
    }

    const sd = JSON.parse(sessionStorage.getItem('data'));

    return getData(App, true, {
      mode: 'roommanagement',
      employee_id: sd.employee.employee_id,
      project_id: project.project_id,
    }, (data) => {
      if (data.status) {
        // remove seconds
        let total = data.data.total.slice(0, -3).split(':');
        data.data.start = data.data.start.slice(0, -3); // eslint-disable-line no-param-reassign
        data.data.end = data.data.end.slice(0, -3); // eslint-disable-line no-param-reassign
        data.data.pause = 'Keine'; // eslint-disable-line no-param-reassign

        // format time to just get the necessary value
        if (total[0] === '00') {
          total = total.splice(1);

          // remove trailing zeros
          total = `${parseInt(total[0], 10)}m`;
        } else if (total[1] === '00') {
          total = total.splice(0);

          const hours = parseInt(total[0], 10);

          if (hours >= 5 && hours < 9) {
            data.data.pause = '30 min'; // eslint-disable-line no-param-reassign
          } else if (hours >= 9) {
            data.data.pause = '1 h'; // eslint-disable-line no-param-reassign
          }

          total = `${hours}h`;
        } else {
          // remove trailing zeros
          const hours = parseInt(total[0], 10);
          const minutes = parseInt(total[1], 10);

          if (hours >= 5 && hours < 9) {
            data.data.pause = '30 min'; // eslint-disable-line no-param-reassign
          } else if (hours >= 9) {
            data.data.pause = '1 h'; // eslint-disable-line no-param-reassign
          }

          total = `${hours}h ${minutes}m`;
        }

        data.data.total = total; // eslint-disable-line no-param-reassign

        // format time to just get the necessary value
        for (const i of Object.keys(data.data.list)) { // eslint-disable-line no-restricted-syntax
          let temp = data.data.list[i].time.slice(0, -3).split(':');

          if (temp[0] === '00') {
            temp = temp.splice(1);

            // remove trailing zeros
            temp = `${parseInt(temp[0], 10)}m`;
          } else if (total[1] === '00') {
            total = total.splice(0);

            total = `${parseInt(total[0], 10)}h`;
          } else {
            // remove trailing zeros
            temp = `${parseInt(temp[0], 10)}h ${parseInt(temp[1], 10)}m`;
          }

          data.data.list[i].time = temp; // eslint-disable-line no-param-reassign
        }

        // add additional project info
        data.data.project = project; // eslint-disable-line no-param-reassign

        // App.template7Data['page:project'] = data.data;

        mv.router.load({
          // url: '/project.html'
          content: nunjucks.render('project.html', data.data),
        });
      } else {
        console.log(data);

        showNotification(App, data.msg);
      }
    });
  },
};
