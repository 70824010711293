import { $$ } from '../helper/utils';

/**
* Get all projects
* @param {Function} App a reference to the App instance of Framework 7
* @param {Object} mv a reference to mainView
* @param {Array} stopwatches
*/
export default (App, mv, stopwatches) => {
  if (!App) {
    console.error('No reference to App provided');
    return false;
  }

  if (!mv) {
    console.error('No reference to the mainView provided');
    return false;
  }

  mv.router.load({
    url: 'index.html',
  });

  setTimeout(() => {
    $$('.page:not(.login), .navbar-inner:first-child:not(:last-child)').forEach((el) => {
      el.parentElement.removeChild(el);
    });

    console.log('clear');
    sessionStorage.clear();
    mv.history = [mv.history.shift()];

    for (let i = 0, len = stopwatches.length; i < len; i += 1) {
      if (stopwatches[i] !== null && 'stop' in stopwatches[i]) {
        stopwatches[i].stop();
      }
    }
  }, 400); // 400 is the time it takes to slide to the new site
};
