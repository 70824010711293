import nunjucks from 'nunjucks';
import getData from '../helper/getData';
import showNotification from '../helper/showNotification';

/**
* Load the room selection view with all selected specialcases and damages
* @param {Function} App a reference to the App instance of Framework 7
* @param {Object} mv a reference to mainView
*/
export default (App, mv) => {
  const specialcases = JSON.parse(sessionStorage.getItem('specialcases'));
  const damages = JSON.parse(sessionStorage.getItem('damages')) || false;

  getData(App, true, {
    mode: 'roommanagementdetail',
    project_id: JSON.parse(sessionStorage.getItem('data')).projecttime.project_id,
    employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
  }, (data) => {
    if (data.status) {
      mv.router.load({
        content: nunjucks.render('affected-rooms.html', {
          specialcases,
          damages,
          data: data.data,
        }),
      });
    } else {
      showNotification(App, data.msg);
    }
  });
};