import nunjucks from 'nunjucks';
import getData from '../helper/getData';
import showNotification from '../helper/showNotification';

export default {
  /**
  * Get all projects
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {Object} mv a reference to mainView
  */
  overview(App, mv) {
    if (!App) {
      console.error('No reference to App provided');
      return false;
    }

    if (!mv) {
      console.error('No reference to the mainView provided');
      return false;
    }

    return getData(App, true, {
      mode: 'projectoverview',
    }, (data) => {
      if (data.status) {
        mv.router.load({
          content: nunjucks.render('chooseproject.html', data),
        });
      } else {
        showNotification(App, data.msg);
      }
    });
  },
  /**
  * Initialise slider
  * App is an instance of Framework7
  * @param {Function} App a reference to the App instance of Framework 7
  * @param {object} sel the sipwer container element
  */
  initSlider(App, sel) {
    App.swiper(sel, {
      speed: 400,
      slidesPerView: 1,
      nextButton: '.swiper-button-next',
    });
  },
};
