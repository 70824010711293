/**
* Show notification for error messages
* @param {Function} App a reference to the App instance of Framework 7
*/
export default (App, message, duration) => {
  if (!App) {
    console.error('No reference to App provided');
    return false;
  }

  return App.addNotification({
    message,
    title: 'Stampfli',
    hold: duration ? duration * 1000 : 3000,
    media: '<svg data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.61 55.61"><path d="M27.81 0a27.81 27.81 0 1 0 27.8 27.8A27.81 27.81 0 0 0 27.81 0zm0 46.4a4.22 4.22 0 1 1 4.21-4.21 4.22 4.22 0 0 1-4.21 4.21zm3-11.57h-6.28l-2-23.34h10.43z"/></svg>'
  });
};
