'use-strict';

/**
* Get a single element for the specified selector.
* Alias for document.querySelector.
*
* @param {string} selector
* @returns HTMLElement
*/
export const $ = function (selector) {
  return document.querySelector(selector);
};

/**
* Get all elements for the specified selector.
* Alias for document.querySelectorAll.
*
* @param {string} selector
* @returns NodeList
*/
export const $$ = function (selector) {
  return document.querySelectorAll(selector);
};

/**
* Get the element for the specified id selector.
* Alias for document.getElementById.
*
* @param {string} id
* @returns HTMLElement
*/
export const $id = function (id) {
  return document.getElementById(id);
};

/**
* Get an element's window offset bounds
*
* @param {HTMLElement} el
* @returns {object} Object containing postions and dimensions
*/
export const offset = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    bottom: rect.top + scrollTop + rect.height,
    width: rect.width,
    height: rect.height,
  };
};

/**
* Delegate an event. Uses event bubbling to trigger only for the specified elements.
* Usefuel if there are a lot of elements that need the same event without adding
* a lot of individual event listeners.
*
* @param {HTMLElement} element The element to attach the event listener to
* @param {string} eventName The event type to listen to
* @param {string} selector A selector to get the elements that should listen to the event
* @param {function} callback The function to execute
* @param {object} options Optional options for the event listener
*/
export const delegate = function (element, eventName, selector, callback, options = {}) {
  if (element === null) {
    console.warn('Element not found', { el: element });
    return;
  }

  element.addEventListener(eventName, (event) => {
    const possibleTargets = element.querySelectorAll(selector);
    const { target } = event;

    for (let i = 0, l = possibleTargets.length; i < l; i += 1) {
      let el = target;
      const p = possibleTargets[i];

      while (el && el !== element) {
        if (el === p) {
          return callback.call(p, event);
        }

        el = el.parentNode;
      }
    }
  }, options);
};

/**
* Add an event listener to and Array/NodeList of DOM Elements
*
* @param {array} elements The url to load data from.
* @param {string} eventName The event type to listen to
* @param {function} callback The function to execute
* @param {object} options Optional options for the event listener
* @returns Promise
*/
export const addEventListenerAll = (elements, eventName, callback, options = {}) => {
  for (let i = 0; i < elements.length; i += 1) {
    const el = elements[i];
    el.addEventListener(eventName, callback, options);
  }
};

/**
* Load content from an url
*
* @param {string} link The url to load data from.
* @param {string} type The data return type. If not json will return text.
* @returns Promise
*/
export const loadFromUrl = (link, type) => {
  return new Promise((resolve, reject) => {
    fetch(link, { credentials: 'same-origin' })
      .then((response) => {
        if (type === 'json') {
          return response.json();
        }

        return response.text();
      })
      .then(res => resolve(res))
      .catch(reason => reject(reason));
  });
};

export const throttle = function (func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds
 *
 * @param {Function} callback Function to execute
 * @param {number} time Time to wait
 * @returns {Function}
 */
export const debounce = function (callback, time) {
  let interval;
  return function (...args) {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback.apply(this, ...args);
    }, time);
  };
};

/**
* Get the closest parent element for a css class
*
* @param {HTMLElement} el
* @param {string} cssclass
* @returns {HTMLElement}
*/
export const closestParent = (el, cssclass) => {
  let parent = el.parentElement;
  while (parent) {
    // don't go higher than the body element
    if (parent.nodeName === 'BODY') {
      break;
    }

    // element found
    if (parent.classList.contains(cssclass) === true) {
      break;
    }

    parent = parent.parentElement;
  }

  return parent;
};

/**
* Create a query string from an object
*
* @param {object} params
* @returns {string}
*/
export const queryString = (params, q = true) => {
  const query = q ? '?' : '';
  return query + Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
};

/**
* Find the index from a DOM Element
*
* @param {object} params
* @returns {Int}
*/
export const getElementIndex = (node) => {
  let index = 0;
  let prev = node.previousElementSibling;
  while (prev !== null) {
    prev = node.previousElementSibling;
    index += 1;
  }

  return index;
};

export const generateUUID = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};