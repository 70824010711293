/* global Framework7, Dom7 */

import nunjucks from 'nunjucks';
import getData from './helper/getData';
import showNotification from './helper/showNotification';
import detectInactivity from './helper/detectInactivity';
import { DOMAIN } from './helper/constants';
import { loadFromUrl, queryString, debounce } from './helper/utils';
import stopwatch from './components/stopwatch';
import login, { loadDashboard } from './components/login';
import logout from './components/logout';
import specialcase from './components/specialcase';
import roomselection from './components/roomselection';
import projects from './components/projects';
import roommanagement from './components/roommanagement';
import projecttime from './components/projecttime';
import Scanner from './components/qrscanner';

nunjucks.configure({ autoescape: true });

// reference Dom7
const $$ = Dom7; // TODO: Refactor with native and then remove

export default function () {
  // clear cache
  sessionStorage.clear();

  const MOBILEMEDIA = window.matchMedia('(min-width: 768px)');
  let stopwatches = [];
  let ina = [];

  // Initialize your app
  const App = new Framework7({
    swipeBackPageAnimateShadow: false,
    swipeBackPageActiveArea: 100,
    statusbarOverlay: false,
    // cache: false,
    preroute(view) {
      // check when not logged in if target site is any other then the root or login site
      // if (!userLoggedIn && !view.activePage.name.match(/#|\/|benutzerid/)) {
      // 	view.router.loadPage('/index.html');
      //     return false; //required to prevent default router action
      // }
      // console.log(view.history)

      if (view.activePage.name === 'index') {
        sessionStorage.clear();
        for (let i = 0, len = stopwatches.length; i < len; i += 1) {
          if (stopwatches[i] !== null) {
            stopwatches[i].stop(true);
          }
        }
        stopwatches = [];
      }

      if (MOBILEMEDIA.matches) {
        setTimeout(() => {
          if ($$('.page + .page .page-content > .container > .row').length) {
            const topoffset = $$('.page + .page .page-content > .container > .row').offset().top;

            // used to make that the content and header don't overlap
            if (topoffset < 150 && topoffset >= 0) {
              const tp = 160 - topoffset;
              $$('.page').css('padding-top', `${tp}px`);
            }
          }
        }, 100);
      }
    },
  });

  // check if it's possible to save to sessionStorage (sessionStorage isn't aviable in private mode)
  try {
    sessionStorage.setItem('checkstorage', 1);
  } catch (e) {
    showNotification(App, 'Lokaler Speicher nicht verfügbar. Wechseln Sie vom privaten Modus in den standard Modus, um die App zu nutzen.', 60);
    return;
  }

  MOBILEMEDIA.addListener((e) => {
    if (e.matches) {
      $$('.page').removeAttr('style');
    }
  });

  if (navigator.storage && navigator.storage.persist) {
    navigator.storage.persist().then((persistent) => {
      if (persistent === false) {
        showNotification(App, 'Persistenter Speicher nicht verfügbar. Lokale App Daten konnen unter Umständen vom Browser gelöscht werden.');
      }
    });
  }

  // Add view
  const mainView = App.addView('.view-main', {
    dynamicNavbar: true,
    domCache: false,
  });


  const hotelid = localStorage.getItem('hotelid');
  if (hotelid === null) {
    showNotification(App, 'Für dieses Gerät wurde noch kein Hotel definiert. Bitte wenden Sie sich an Ihren Administrator.', 15);
  } else {
    localStorage.setItem('tablenumber', hotelid > 1 ? `${hotelid}_` : '');
  }


  App.onPageInit('hotellist', () => {
    $$('.hotellist .save-hotel').click((e) => {
      e.preventDefault();

      const sel = $$('.hotellist input:checked');

      try {
        localStorage.setItem('hotelid', sel.val());
        showNotification(App, `Hotel ${sel.attr('data-hotel')} wurde gespeichert. Die App wird gleich neugestartet.`, 4);
        setTimeout(() => window.location.reload(), 3500);
      } catch (error) {
        showNotification(App, 'Hotel konnte nicht gespeichert werden.');
      }
    });
  });


  // save index html for later
  const indexpage = $$('.pages').html().replace('page login', 'page login page-on-left');
  const indexnav = $$('.navbar').html().replace('navbar-inner', 'navbar-inner navbar-on-left');
  const navContent = document.querySelector('.panel-left > .content-block');
  // const reportsLink = document.createElement('a');
  // reportsLink.className = 'reportslink';
  // reportsLink.href = '#';
  // reportsLink.textContent = 'Monatsberichte';

  $$('.bntid').click((e) => {
    mainView.router.load({
      content: nunjucks.render('benutzerid.html', {}),
    });
  });

  const scanner = new Scanner(App);
  $$(document).on('click', '.qrscan', () => {
    scanner.openScanner();
  });

  window.addEventListener('qrcode:scan', (e) => {
    login(App, mainView, e.detail.result);
  });

  $$(document).on('pageAfterAnimation', (e) => {
    const { name } = e.detail.page.view.activePage;

    if (name === 'dashboard') {
      // make back button click go back to the index site
      $$('.panel-left .back')[0].dataset.force = true;

      // const panelReportsLink = document.querySelector('.panel-left .reportslink');
      // if (panelReportsLink === null) {
      //   navContent.appendChild(reportsLink);
      // }
    } else {
      $$('.panel-left .back')[0].dataset.force = false;
    }

    console.log(name);

    if (name.match(/dashboard|chooseproject|project|projectworks|rooms|roomcheck|report-overview|report-hotels|report-view/)) {
      ina[0] = ina[0] || detectInactivity(App, mainView, stopwatches, name, 60);
    }
  });

  function startWorktime() {
    const sd = JSON.parse(sessionStorage.getItem('data'));
    const loginData = {
      data: {
        employee_id: sd.employee.employee_id,
        number: sd.employee.number,
      },
    };

    stopwatches[0].stop(true);

    loadDashboard(App, mainView, loginData, hotelid, false)
      .then(() => {
        stopwatches[0].start();
        sessionStorage.setItem('timerstopped', false);
        showNotification(App, 'Arbeitszeit gestartet');
        const stopglobaltime = $$('.stopglobaltime');
        const stopIcon = $$('.stopglobaltime .stop-icon');
        const startIcon = $$('.stopglobaltime .play-icon');

        //change title
        stopglobaltime.attr('title', 'Arbeitszeit stoppen');
        startIcon.attr('hidden', '');
        stopIcon.removeAttr('hidden');
      });
  }

  function stopWorktime() {
    if (sessionStorage.getItem('timerstopped') !== 'true') {
      getData(App, true, {
        mode: 'stopworkingtime',
        employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
      }, (data) => {
        if (data.status) {
          stopwatches[0].stop(true);
          // Stopwatch = null;
          sessionStorage.setItem('timerstopped', true);
          showNotification(App, 'Arbeitszeit gestoppt');
          const stopglobaltime = $$('.stopglobaltime');
          const stopIcon = $$('.stopglobaltime .stop-icon');
          const startIcon = $$('.stopglobaltime .play-icon');

          //change title
          stopglobaltime.attr('title', 'Arbeitszeit starten');
          stopIcon.attr('hidden', '');
          startIcon.removeAttr('hidden');

        } else {
          showNotification(App, data.msg);
        }
      });
    } else {
      startWorktime();
    }
  }

  $$(document).on('pageInit', (e) => {
    // Page Data contains all required information about loaded and initialized page
    const { page } = e.detail;
    const { name } = page.view.activePage;
    const logoutBtn = $$('.navbar-inner .right a');

    if (logoutBtn.length) {
      logoutBtn.click((ev) => {
        ev.preventDefault();

        logout(App, mainView, stopwatches);
        // navContent.removeChild(reportsLink);
      });
    }

    if (name === 'dashboard') {
      if (sessionStorage.getItem('timerstopped') !== 'true') {
        if (stopwatches.length > 0 && stopwatches[0] !== null && 'stop' in stopwatches[0]) {
          $$('.stopglobaltime').off('click', stopWorktime);
          stopwatches[0].stop(true);
          stopwatches[0] = null;
        }

        const el = $$('#sw')[0];
        const Stopwatch = stopwatch(el, 'global');
        Stopwatch.start();
        stopwatches[0] = Stopwatch;

        $$('.stopglobaltime').on('click', stopWorktime);
      }

      if (stopwatches.length > 1 && stopwatches[1] !== null && 'stop' in stopwatches[1]) {
        stopwatches[1].stop(true);
        stopwatches[1] = null;
      }

      // start projecttimer or else the clock won't be up to date
      const elPj = $$('#projecttime')[0];
      const StopwatchPj = stopwatch(elPj, 'project');
      stopwatches[1] = StopwatchPj;
      StopwatchPj.start();
    }
  });

  // const stopStopwatches = () => {
  //   for (let i = 0, len = stopwatches.length; i < len; i += 1) {
  //     stopwatches[i].stop(true);
  //   }
  //   stopwatches = [];
  // };

  // App.onPageBeforeRemove('project', stopStopwatches);
  // App.onPageBeforeRemove('projectworks', stopStopwatches);
  // App.onPageBeforeRemove('dashboard', stopStopwatches);

  App.onPageInit('benutzerid', () => {
    $$('#useridlogin').on('submit', function (e) {
      e.preventDefault();

      console.log(this.userid.value);
      login(App, mainView, this.userid.value);
      $$('#l_userid').blur();
    });
  });

  App.onPageAfterAnimation('benutzerid', () => {
    if (document.documentElement.classList.contains('is-mobile') === false) {
      $$('#l_userid').focus();
    }
  });

  App.onPageAfterAnimation('dashboard', () => {
    if (sessionStorage.getItem('fromlogin') === 'true') {
      // remove last sites from history
      mainView.history.splice(1, 2);

      // remove login content from dom
      $$('.view-main .page-on-left').remove();

      // add index page as last page
      $$(indexpage).insertBefore('.page');
      $$(indexnav).insertBefore('.view-main .nabvar-inner');

      sessionStorage.setItem('fromlogin', false);
    }
  });

  App.onPageInit('dashboard', () => {
    $$('.projectbtn').click((e) => {
      e.preventDefault();

      projects.overview(App, mainView);
    });

    $$('.checkrooms').click((e) => {
      e.preventDefault();
      const sd = JSON.parse(sessionStorage.getItem('data'));

      getData(App, true, {
          mode: 'roomlist',
          employee_id: sd.employee.employee_id,
        }, (data) => {
          if (data.status) {
            mainView.router.load({
              content: nunjucks.render('roomcheck.html', {
                data: data.data,
              }),
            });
          } else {
            showNotification(App, data.msg);
          }
        }
      );
    });

    $$('.finish').click(function (e) {
      e.preventDefault();

      const project = JSON.parse(this.dataset.info);
      const sd = JSON.parse(sessionStorage.getItem('data'));
      const pt = sd.projecttime;
      const pw = pt.projectworks;

      if (pw.length) {
        for (let i = 0, pwlen = pw.length; i < pwlen; i += 1) {
          pw[i].details = pw[i].details.replace(/\r\n|\r|\n/g, '<hr>');
        }

        mainView.router.load({
          content: nunjucks.render('projectworks.html', {
            pw,
            project,
            project_time: pt,
          }),
        });
      } else if (parseInt(pt.roommanagement, 10) === 1) {
        getData(App, true, {
          mode: 'roommanagement',
          employee_id: sd.employee.employee_id,
          project_id: project.project_id,
        }, (data) => {
          if (data.status) {
            // remove seconds
            let total = data.data.total.slice(0, -3).split(':');
            data.data.start = data.data.start.slice(0, -3); // eslint-disable-line no-param-reassign
            data.data.end = data.data.end.slice(0, -3); // eslint-disable-line no-param-reassign
            data.data.pause = 'Keine'; // eslint-disable-line no-param-reassign

            // format time to just get the necessary value
            if (total[0] === '00') {
              total = total.splice(1);

              // remove trailing zeros
              total = `${parseInt(total[0], 10)}m`;
            } else if (total[1] === '00') {
              total = total.splice(0);

              const hours = parseInt(total[0], 10);

              if (hours >= 5 && hours < 9) {
                data.data.pause = '30 min'; // eslint-disable-line no-param-reassign
              } else if (hours >= 9) {
                data.data.pause = '1 h'; // eslint-disable-line no-param-reassign
              }

              total = `${hours}h`;
            } else {
              // remove trailing zeros
              const hours = parseInt(total[0], 10);
              const minutes = parseInt(total[1], 10);

              if (hours >= 5 && hours < 9) {
                data.data.pause = '30 min'; // eslint-disable-line no-param-reassign
              } else if (hours >= 9) {
                data.data.pause = '1 h'; // eslint-disable-line no-param-reassign
              }

              total = `${hours}h ${minutes}m`;
            }

            data.data.total = total; // eslint-disable-line no-param-reassign

            // format time to just get the necessary value
            for (const i of Object.values(data.data.list)) { // eslint-disable-line no-restricted-syntax
              let temp = i.time.slice(0, -3).split(':');

              if (temp[0] === '00') {
                temp = temp.splice(1);

                // remove trailing zeros
                temp = `${parseInt(temp[0], 10)}m`;
              } else if (total[1] === '00') {
                total = total.splice(0);

                total = `${parseInt(total[0], 10)}h`;
              } else {
                // remove trailing zeros
                temp = `${parseInt(temp[0], 10)}h ${parseInt(temp[1], 10)}m`;
              }

              i.time = temp; // eslint-disable-line no-param-reassign
            }

            // add additional project info
            data.data.project = project; // eslint-disable-line no-param-reassign
            data.data.project_time = pt; // eslint-disable-line no-param-reassign

            // console.log(data.data.project);

            mainView.router.load({
              content: nunjucks.render('project.html', data.data),
            });
          }
        });
      } else {
        const stopped = Boolean(sessionStorage.getItem('projectStopped'));

        if (stopped === true) {
          projecttime.finalTime(App, mainView);
        } else {
          projecttime.stop(App, mainView, () => {
            projecttime.finalTime(App, mainView);
          });
        }
      }
    });

    $$('.dashboard .pauseworktime').click(function (e) {
      e.preventDefault();

      // console.log(stopwatches[1].isRunning());
      const { projecttime: pt } = JSON.parse(sessionStorage.getItem('data'));
      const { action } = this.dataset;

      // projecttime.pause(App, mainView, () => {
      if (action === 'stop') {
        projecttime.stop(App, mainView, () => {
          this.dataset.action = 'start';
          stopwatches[1].stop(true);
          sessionStorage.setItem('projectStopped', true);
          this.querySelector('span').textContent = this.dataset.play;
          this.querySelector('.stop-icon').setAttribute('hidden', '');
          this.querySelector('.play-icon').removeAttribute('hidden');
          showNotification(App, 'Projekt wurde gestoppt');
        });
      } else {
        // stopwatches[1].start();
        projecttime.start(App, mainView, pt.project_id, stopwatches);
        sessionStorage.setItem('projectStopped', false);
        this.dataset.action = 'stop';
        this.querySelector('span').textContent = this.dataset.stop;
        this.querySelector('.play-icon').setAttribute('hidden', '');
        this.querySelector('.stop-icon').removeAttribute('hidden');
      }
    });
  });

  App.onPageInit('chooseproject', () => {
    $$('.toproject').click(function (e) {
      e.preventDefault();

      // get project info from data attribute
      const project = JSON.parse(this.dataset.info);
      const projectworks = !!this.dataset.projectworks;

      if (project.roommanagment) {
        roommanagement.show(App, mainView, project);
      } else if (projectworks) {
        const pw = [];
        const sd = JSON.parse(sessionStorage.getItem('data')).projectoverview.data;

        for (let i = 0, len = sd.length; i < len; i += 1) {
          if (parseInt(sd[i].project_id, 10) === project.project_id) {
            for (let j = 0, pwlen = sd[i].projectworks.length; j < pwlen; j += 1) {
              // replace linebreaks
              sd[i].projectworks[j].details = sd[i].projectworks[j].details.replace(/\r\n|\r|\n/g, '<hr>');
              pw.push(sd[i].projectworks[j]);
            }

            break;
          }
        }

        mainView.router.load({
          content: nunjucks.render('projectworks.html', {
            pw,
            project,
          }),
        });
      } else {
        projecttime.start(App, mainView, project.project_id, stopwatches);
      }
    });
  });

  App.onPageInit('project', () => {
    if (stopwatches.length > 1 && stopwatches[1] !== null && 'stop' in stopwatches[1]) {
      $$('.stopglobaltime').off('click', stopWorktime);
      stopwatches[1].stop(true);
      stopwatches[1] = null;
    }

    // update projecttime by using the difference from the worktime and its current timmer
    // const et = JSON.parse(sessionStorage.getItem('elapsedtimes'));
    // console.log(stopwatches);
    // const workTimerDiff = stopwatches[0].getSeconds() - et.work;

    // et.project += workTimerDiff;
    // sessionStorage.setItem('elapsedtimes', JSON.stringify(et));

    // init project stopwatch
    const { projecttime: pt } = JSON.parse(sessionStorage.getItem('data'));
    if (pt) {
      const el = $$('#projecttime')[0];
      const Stopwatch = stopwatch(el, 'project');
      stopwatches[1] = Stopwatch;
      Stopwatch.start();

      const stopped = Boolean(sessionStorage.getItem('projectStopped'));
      if (stopped === true) {
        setTimeout(() => {
          Stopwatch.stop(true);
        }, 1500);
      }
    }

    $$('.project .projectrooms').click(function (e) {
      e.preventDefault();

      // get project info from data attribute
      const project = JSON.parse(this.dataset.info);

      getData(App, true, {
        mode: 'roommanagementdetail',
        employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
        project_id: project.project_id,
      }, (data) => {
        if (data.status) {
          mainView.router.load({
            content: nunjucks.render('rooms.html', {
              data: data.data,
              projectname: project.projectname,
            }),
          });
        } else {
          showNotification(App, data.msg);
        }
      });
    });

    $$('.project .starttime').click(function (e) {
      e.preventDefault();

      const projectid = parseInt(this.dataset.projectid, 10);
      projecttime.start(App, mainView, projectid, stopwatches);
    });

    $$('.project .stoptime').click((e) => {
      e.preventDefault();

      const stopped = Boolean(sessionStorage.getItem('projectStopped'));

      if (stopped === true) {
        mainView.router.load({
          content: nunjucks.render('stoptime.html', {
            projectname: JSON.parse(sessionStorage.getItem('data')).projectname,
          }),
        });
      } else {
        projecttime.stop(App, mainView, () => {
          mainView.router.load({
            content: nunjucks.render('stoptime.html', {
              projectname: JSON.parse(sessionStorage.getItem('data')).projectname,
            }),
          });
        });
      }
    });
  });

  App.onPageInit('projectworks', () => {
    if (document.querySelectorAll('.project-works .swiper-slide').length > 1) {
      projects.initSlider(App, '.project-works .swiper-container');
    }

    $$('.project-works .starttime').click(function (e) {
      e.preventDefault();

      const projectid = parseInt(this.dataset.projectid, 10);
      projecttime.start(App, mainView, projectid, stopwatches);
    });

    $$('.project-works .stoptime').click((e) => {
      e.preventDefault();

      projecttime.stop(App, mainView, () => {
        projecttime.finalTime(App, mainView);
      });
    });
  });

  App.onPageInit('stoptime', () => {
    $$('.hasspecialcase').click((e) => {
      e.preventDefault();

      getData(App, false, {
        mode: 'getspecialcases',
      }, (data) => {
        if (data.status) {
          mainView.router.load({
            content: nunjucks.render('choose-specialcase.html', {
              data: data.cases,
            }),
          });
        } else {
          showNotification(App, data.msg);
        }
      });
    });

    $$('.nospecialcases').click((e) => {
      e.preventDefault();

      projecttime.finalTime(App, mainView);
    });
  });

  let specialcaseslider = null;
  App.onPageInit('specialcase', () => {
    if (document.querySelectorAll('.choose-specialcase .swiper-slide').length > 1) {
      specialcaseslider = specialcase.initSlider(App, '.choose-specialcase .swiper-container', '.choose-specialcase .pagination');
    }

    specialcase.initSelection($$('.choose-specialcase .specialcase'));

    $$('.choose-specialcase .choose').click((e) => {
      e.preventDefault();

      const selected = $$('.choose-specialcase .specialcase.selected');
      const selectedAr = [];
      let hasDamage = false;
      let isOther = false;

      if (!selected.length) {
        showNotification(App, 'Es muss mindestens ein Spezialfall ausgewählt werden');
        return false;
      }

      for (let i = 0, len = selected.length; i < len; i += 1) {
        selectedAr.push({
          id: selected[i].dataset.id,
          name: $$(selected[i]).find('h6').html(),
          type: $$(selected[i]).data('type') || '',
        });

        if (selectedAr[selectedAr.length - 1].type === 'damage') {
          hasDamage = true;
        }

        if (selectedAr[selectedAr.length - 1].type === 'other') {
          isOther = true;
        }
      }

      // save specialcases is sessionStorage
      sessionStorage.setItem('specialcases', JSON.stringify(selectedAr));

      if (isOther) {
        mainView.router.load({
          content: nunjucks.render('describe-case.html', {}),
        });
      } else if (hasDamage) {
        getData(App, false, {
          mode: 'getspecialcases',
        }, (data) => {
          if (data.status) {
            mainView.router.load({
              content: nunjucks.render('choose-damages.html', {
                data: data.damages,
              }),
            });
          } else {
            showNotification(App, data.msg);
          }
        });
      } else {
        roomselection(App, mainView);
      }
    });
  });

  App.onPageInit('damages', () => {
    specialcase.initSelection($$('.choose-damages .specialcase'));

    $$('.choose-damages .choose').click((e) => {
      e.preventDefault();

      const selected = $$('.choose-damages .specialcase.selected');
      const selectedAr = [];

      if (!selected.length) {
        showNotification(App, 'Es muss mindestens ein Schaden ausgewählt werden');
        return false;
      }

      for (let i = 0, len = selected.length; i < len; i += 1) {
        selectedAr.push({
          id: selected[i].dataset.id,
          name: $$(selected[i]).find('h6').html(),
        });
      }

      // save damages is sessionStorage
      sessionStorage.setItem('damages', JSON.stringify(selectedAr));

      roomselection(App, mainView);
    });
  });

  App.onPageInit('describecase', () => {
    $$('.describe-case .choose').click((e) => {
      e.preventDefault();

      const casemsg = $$('.describe-case textarea').val();

      if (!casemsg.length) {
        showNotification(App, 'Beschreibe den Vorfall');
        return false;
      }

      sessionStorage.setItem('casemsg', casemsg);

      const specialcases = JSON.parse(sessionStorage.getItem('specialcases'));
      let hasDamage = false;

      for (let i = 0, len = specialcases.length; i < len; i += 1) {
        if (specialcases[i].type === 'damage') {
          hasDamage = true;
          break;
        }
      }

      if (hasDamage) {
        getData(App, false, {
          mode: 'getspecialcases',
        }, (data) => {
          if (data.status) {
            mainView.router.load({
              content: nunjucks.render('choose-damages.html', {
                data: data.damages,
              }),
            });
          } else {
            showNotification(App, data.msg);
          }
        });
      } else {
        roomselection(App, mainView);
      }
    });
  });

  App.onPageInit('affectedrooms', () => {
    if (document.querySelectorAll('.affected-rooms .swiper-slide').length > 1) {
      specialcase.initSlider(App, '.affected-rooms .swiper-container', '.affected-rooms .pagination');
    }

    specialcase.initSelection($$('.affected-rooms .roomsel'));

    $$('.affected-rooms .choose').click((e) => {
      e.preventDefault();

      const selected = $$('.affected-rooms .selected');
      const specialcases = sessionStorage.getItem('specialcases') ? JSON.parse(sessionStorage.getItem('specialcases')) : [];
      const damages = sessionStorage.getItem('damages') ? JSON.parse(sessionStorage.getItem('damages')) : [];
      const caseArr = [];
      const dmgArr = [];
      const rooms = [];

      if (!selected.length) {
        showNotification(App, 'Es muss mindestens ein Raum ausgewählt werden');
        return false;
      }

      for (let i = 0, len = specialcases.length; i < len; i += 1) {
        if (specialcases[i].type === 'damage') {
          for (let j = 0, dlen = damages.length; j < dlen; j += 1) {
            dmgArr.push(damages[j].id);
          }

          caseArr.push({ id: specialcases[i].id, damages: dmgArr });
        } else {
          caseArr.push({ id: specialcases[i].id });
        }
      }

      for (let i = 0, len = selected.length; i < len; i += 1) {
        const roomcases = {
          room_id: selected[i].dataset.roomid,
          cases: caseArr,
          message: sessionStorage.getItem('casemsg') || '',
        };

        rooms.push(roomcases);
      }

      getData(App, true, {
        mode: 'savespecialcases',
        employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
        special_cases: JSON.stringify(rooms),
      }, (data) => {
        if (data.status) {
          sessionStorage.removeItem('specialcases');
          sessionStorage.removeItem('damages');
          sessionStorage.removeItem('casemsg');

          mainView.router.back({
            force: true,
            content: nunjucks.render('stoptime.html', {
              projectname: JSON.parse(sessionStorage.getItem('data')).projectname,
            }),
          });
        } else {
          showNotification(App, data.msg);
        }
      });
    });
  });

  App.onPageInit('confirmtime', () => {
    $$('.confirm-time .choose').click((e) => {
      e.preventDefault();

      const comment = $$('.confirm-time textarea').val();

      getData(App, false, {
        mode: 'setprojectconfirm',
        employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
        project_id: JSON.parse(sessionStorage.getItem('data')).projecttime.project_id,
        comment,
      }, (data) => {
        if (data.status) {
          const sessiondata = JSON.parse(sessionStorage.getItem('data'));
          sessiondata.projecttime = '';
          sessiondata.projectname = '';

          sessionStorage.setItem('data', JSON.stringify(sessiondata));

          const timedata = {
            ajax: 1,
            modulname: 'ajax',
            mode: 'dashboard',
            employee_id: sessiondata.employee.employee_id,
            number: sessiondata.employee.number,
          };
          const url = `${DOMAIN}/admin/index1.php${queryString(timedata)}`;
          const request = loadFromUrl(url, 'json');

          request.then((dashboard) => {
            if (dashboard.status === false) {
              App.addNotification({
                title: 'Stampfli',
                message: dashboard.msg,
                hold: 3000,
              });
            } else {
              dashboard.data.domainname = DOMAIN; // eslint-disable-line no-param-reassign

              mainView.router.back({
                force: true,
                content: nunjucks.render('dashboard.html', dashboard.data),
              });
            }
          });
        } else {
          showNotification(App, data.msg);
        }
      });
    });
  });

  // App.onPageInit('monthly-report', () => {

  // });

  const loadReportOverview = (response) => {
    const data = { years: {} };
    const currentDate = new Date();
    let startDate = new Date(`${currentDate.getFullYear()}/01/01`);

    if (response && response.status === 1) {
      const dates = Object.values(response.data[0]).map(d => new Date(d));
      const minDate = new Date(Math.min.apply(null, dates));
      minDate.setDate(1);
      startDate = minDate;
    }

    const { employee } = JSON.parse(sessionStorage.getItem('data'));
    const { hotelids } = employee;
    const hotelNames = employee.hotels.replace(',', ', ');
    const hotels = [];
    // data.hotels = {
    //   hotelids,
    //   hotelNames,
    //   // hasMultiple: Boolean(hotelids.length - hotelids.replace(',', '').length),
    // };


    currentDate.setDate(1);
    console.log(currentDate);

    while (currentDate >= startDate) {
      const currentYear = currentDate.getFullYear();
      const month = new Intl.DateTimeFormat('de-DE', { month: '2-digit' }).format(currentDate);
      const monthLong = new Intl.DateTimeFormat('de-DE', { month: 'long' }).format(currentDate);
      // const date = new Intl.DateTimeFormat('de-DE', dateOptions).format(currentDate);
      const date = `${currentYear}-${month}-01`;

      if (currentYear in data.years === false) {
        data.years[currentYear] = [];
      }

      data.years[currentYear].push({ month: monthLong, date, hotels });

      const prevMonth = currentDate.getMonth() - 1;

      if (prevMonth < 0) {
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        currentDate.setMonth(11);
      } else {
        currentDate.setMonth(prevMonth);
      }
    }

    // sort data by year descending
    const sortedYears = Object.keys(data.years).reverse().map(year => (
      { year, months: data.years[year] }
    ));
    const sortedData = {
      years: sortedYears,
      hotels: {
        hotelids,
        hotelNames,
      },
    };

    mainView.router.load({
      content: nunjucks.render('report-overview.html', { data: sortedData }),
    });
  };

  $$(navContent).on('click', 'a', function (e) {
    const cssClass = this.className;

    if (cssClass.indexOf('reportslink') !== -1) {
      e.preventDefault();

      const sessiondata = JSON.parse(sessionStorage.getItem('data'));
      getData(App, false, {
        mode: 'getfirstday',
        employee_id: sessiondata.employee.employee_id,
      }, (data) => {
        if (data.status) {
          loadReportOverview(data);
        } else {
          loadReportOverview();
        }
      });
    }
  });

  const loadReport = (date, hotel, hotelview = false) => {
    const sessiondata = JSON.parse(sessionStorage.getItem('data'));
    // const timedata = {
    //   ajax: 1,
    //   modulname: 'ajax',
    //   mode: 'getreport',
    //   employee_id: sessiondata.employee.employee_id,
    //   hotel_id: hotel,
    //   date,
    // };
    // const url = `${DOMAIN}/admin/index1.php${queryString(timedata)}`;
    // const request = loadFromUrl(url, 'json');

    // request.then((response) => {
    //   console.log(response);
    //   const { data, status } = response;

    //   if (status === 1) {
    //     // reformat date
    //     const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    //     const dateRange = data.date.split(' - ').map(d => (
    //       new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(d))
    //     )).join(' - ');

    //     console.log(dateRange);


    //     mainView.router.load({
    //       content: nunjucks.render('report.html', { data, date: dateRange }),
    //     });
    //   } else {

    //   }

    // });

    getData(App, true, {
      mode: 'getreport',
      employee_id: sessiondata.employee.employee_id,
      hotel_id: hotel,
      date,
    }, (response) => {
      const { data, status } = response;

      if (status === 1) {
        if (parseFloat(data.total) === 0) {
          showNotification(App, `Keine Arbeitszeiten in diesen Zeitraum ${hotelview === true ? 'für dieses Hotel ' : ''}vorhanden.`);
          return;
        }

        // reformat date
        const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const dateRangeArr = data.date.split(' - ');
        const reportDate = dateRangeArr[0].replace(/-/g, '/');
        console.log(reportDate);

        const dateRange = dateRangeArr.map(d => (
          new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(d))
        )).join(' - ');

        mainView.router.load({
          content: nunjucks.render('report.html', {
            data,
            hotel,
            reportDate,
            date: dateRange,
          }),
        });
      } else {
        showNotification(App, data.msg);
      }
    });
  };

  App.onPageInit('report-overview', () => {
    $$('.report-year-list .view-report').off('click').click(function (e) {
      e.preventDefault();

      const { date } = this.dataset;
      const hotelIds = this.dataset.hotel.split(',').map(Number);

      if (hotelIds.length > 1) {
        const { employee } = JSON.parse(sessionStorage.getItem('data'));
        const hotelNames = employee.hotels.split(',');
        const hotels = [];

        hotelNames.forEach((hotel, i) => {
          hotels.push({
            hotel,
            id: hotelIds[i],
          });
        });

        mainView.router.load({
          content: nunjucks.render('report-hotels.html', { date, hotels }),
        });
      } else {
        loadReport(date, hotelIds);
      }
    });
  });

  App.onPageInit('report-hotels', () => {
    $$('.report-hotels .view-report').off('click').click(function (e) {
      e.preventDefault();

      const { hotel, date } = this.dataset;
      loadReport(date, hotel, true);
    });
  });

  App.onPageInit('report-view', () => {
    if (document.querySelector('.signature-pad') !== null) {
      import('./components/signature').then((Signature) => {
        Signature.default(App);
      }).catch(() => {
        showNotification(App, 'Signatur Funktionalität konnte nicht geladen werden');
      });
    }
  });

  App.onPageInit('roomcheck', () => {
    $$('.card input').each(function (index, el) {
      const change = debounce(function () {
        console.log([el.checked, el.value]);
        getData(App, true, {
          mode: 'setroomlist',
          employee_id: JSON.parse(sessionStorage.getItem('data')).employee.employee_id,
          check: el.checked ? 1 : 0,
          room_id: el.value,
        }, (data) => {
          if (data.status) {
            console.log(data);
          } else {
            el.checked = !el.checked;
            showNotification(App, data.msg);
          }
        });
      }, 500);

      $$(this).on('change', change);
    });

    $$('.backto-dashboard').click(function () {
      const data = JSON.parse(sessionStorage.getItem('data'));
      const dashboardData = {
        employee_data: data.employee,
        employee_time: data.employeetime,
        project_time: data.projecttime,
        project_name: data.projectname,
      };

      mainView.router.back({
        force: true,
        content: nunjucks.render('dashboard.html', dashboardData),
      });
    });
  });
}
